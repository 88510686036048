/**
 * 
 * 
 * 
*/

import { Box, Container, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import ayplayer from './ayplayer.png';
import ayplayerPorts from './ayplayer-ports.png';
import adapter from './player-adapter.png';
import minijack from './minijack.png';
import tulp from './tulp.png';



export const Support = (props) => {
  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        bgcolor: 'secondary.light',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <Container>
        <Box sx={{flexGrow: 1}} />
        <Box sx={{margin: {sx: 0, md: 4}, padding: {xs:0, md: 4}}}>
          <Typography variant="h2">FAQ</Typography>
          <List>
            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>1. Wat is een AyPlay Mediaplayer?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  De AyPlay Mediaplayer is een audiospeler ontworpen voor vaste installatie in winkels en showrooms. Het is verbonden met het internet of een intranet, waardoor het automatisch nieuwe, altijd actuele muziekafspeellijsten ontvangt en af kan spelen die passen bij het seizoen en het winkelconcept. Commercials kunnen ook worden afgespeeld.
                </Typography>
                <Box component={"img"} alt="AyPlayer" src={ayplayer} sx={{m:2}}/> 
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>2. Wat zit er in de doos bij ontvangst?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Tijdens de verzending worden 3 items verzonden: <br />
                   &nbsp;&nbsp;1. AyPlay Mediaplayer <br />
                   &nbsp;&nbsp;2. Stroomkabel met adapter<br />
                   &nbsp;&nbsp;3. Audiokabel<br />
                </Typography>
                <Box component={"img"} alt="AyPlayer" src={ayplayer} sx={{m:2}}/> 
                <Box component={"img"} alt="adapter" src={adapter} sx={{m:2}}/> 
                <Box component={"img"} alt="audiocable" src={minijack} sx={{m:2}}/> 
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>3. Hoe installeer ik de AyPlay Mediaplayer voor het eerste gebruik?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Om de AyPlay Mediaplayer in te schakelen dienen 3 bijgesloten kabels te worden aangesloten: <br />
                  &nbsp;&nbsp;1. Netwerkkabel <br />
                  &nbsp;&nbsp;2. Audiokabel<br />
                  &nbsp;&nbsp;3. Stroomkabel<br />
                </Typography>
                <Box component={"img"} alt="AyPlayer" src={ayplayerPorts} sx={{m:2}}/>
                <Typography sx={{m: 2, ml: 3}}>Voordat u gaat aansluiten maakt u eerst met uw mobiel foto’s van de situatie hoe alles nu aangesloten zit, voordat u de reeds aanwezige kabeltjes eruit gaat halen.</Typography>
                <Typography sx={{m: 2, ml: 3}}>Volgorde aansluiten Mediaplayer:</Typography>
                <Typography sx={{m: 2, ml: 4}}>
    1. Plaats de netwerk (internet)kabel op de plaats van het vierkante gaatje zoals op de foto te zien is (NETWERK). <br />
    2. U verbindt de audiokabel middels het dunne pennetje (de mini-jack/koptelefoon aansluiting) in het ronde AUDIO gaatje zoals op de foto weergegeven.<br />
    3. Sluit de twee andere rode en witte plugjes aan (de andere zijde van het snoer van de audiokabel) op de versterker op precies dezelfde plaats als waar het oude kabeltje met de twee plugjes eerst zat op de versterker, kijk voor de zekerheid op de door u gemaakte foto’s vooraf, zodat u de twee plugjes in dezelfde gaatjes plaatst als waar het oude snoertje eerst zat.<br />
    4. Sluit dan de stroomkabel aan (POWER op de foto). Deze stekker kan er op één manier in. Kijkt u goed naar de vorm van de stekker. <br />
                </Typography>
                <Typography sx={{m: 2, ml: 3}}>De AyPlay Mediaplayer start op en probeert hierna direct een update uit te voeren, na een aantal minuten zal de muziek starten met afspelen, dit kan maximaal 30 minuten duren.</Typography>
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>4. Is mijn versterker geschikt?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Iedere audio-versterker die beschikt over de mogelijkheid van twee plugjes te plaatsen  (tulp (RCA) ingang) is geschikt om aangesloten te worden op de AyPlay Mediaplayer.
                </Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Op de foto hieronder ziet u een Tulp( RCA) ingang (kleuren kunnen afwijken, maar nooit geel).
                </Typography>
                <Box component={"img"} alt="AyPlayer" src={tulp} sx={{m:2}}/>
                <Typography sx={{m: 2, ml: 3}}>
                  Mocht uw versterker deze ingangen niet hebben en dit niet ondersteunen, dan heeft u een oud systeem. Neem dan zo snel mogelijk contact met ons op.
                </Typography>
                
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>5. Hoe stel ik het volume in?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Op de AyPlay Mediaplayer is het niet mogelijk om het volume te regelen. De AyPlay Mediaplayer geeft altijd een gelijk volume. Het volume zal daarom ingesteld dienen te  worden op de versterker met de volumeknop.
                </Typography>
              </ListItemText>
            </ListItem>

            
            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>6. Wat als ik geen muziek hoor?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Er kunnen meerdere oorzaken zijn voor het niet horen van muziek. 
                </Typography>
                <Typography sx={{m: 2, ml: 4}}>
                  1. Kabels niet correct aangesloten
                  </Typography>
                <Typography sx={{m: 2, ml: 6}}>
                      - Controleer de aangesloten stroomkabel<br />
                      - Controleer de audiokabel bij de AyPlay Mediaplayer en versterker<br />
                      - Controleer de internet (netwerk) aansluiting
                </Typography>
                <Typography sx={{m: 2, ml: 4}}>
                  2. Versterker staat uit
                  </Typography>
                  <Typography sx={{m: 2, ml: 6}}>
                      - Schakel de versterker in, controleer of deze ingesteld staat op de twee kleine plugjes (tulp/RCA aansluiting).
                  </Typography>
                  <Typography sx={{m: 2, ml: 4}}>
                  3. Versterker staat verkeerd ingesteld
                </Typography>
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>7. Hoe krijg ik nieuwe muziek?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Elke nacht worden automatisch updates gedraaid voor nieuwe afspeellijsten van de muziek. Dit betekent dat de muziek regelmatig een vernieuwde actuele mix zal zijn.
                </Typography>
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>8. Kan ik zelf muziek kiezen?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Het is niet mogelijk om zelf muziek te kiezen. De muziek playlist wordt centraal met zorg samengesteld voor de AyPlay Mediaplayer. De lijst is aangepast op de winkelformule en tijd van het jaar. Regelmatig zal deze muziek geüpdate worden.
                </Typography>
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>9. Ik krijg geen nieuwe muziek of hoor telkens hetzelfde nummer</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Mogelijk is de netwerkaansluiting niet correct aangesloten en is de update in de nacht niet goed uitgevoerd. Controleer de Internet (netwerk)aansluiting.
                </Typography>
              </ListItemText>
            </ListItem>


            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>10. Ik hoor telkens muziek met het woord “Help” hierin?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Je AyPlay Mediaplayer werkt niet goed, dit kan verschillende redenen hebben. 
De muziek die je dan hoort is een noodlijst die enkel hoorbaar is wanneer er een fout optreedt met de internetverbinding. Herstart de AyPlay Mediaplayer door de stroomkabel los te koppelen en na 20 seconden weer te verbinden, zo wordt de player gereset.

De AyPlay Mediaplayer start opnieuw op en zal opnieuw proberen te verbinden.

Wanneer na 15 minuten geen muziek, of wederom nummers met: “HELP” hoorbaar zijn moet de internetverbinding gecontroleerd worden.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Typography variant="h6" sx={{m: 2}}>Heeft u andere vragen?</Typography>
                <Typography sx={{m: 2, ml: 3}}>
                  Neem contact op met Sanamedia voor uw vragen. Vermeld in de communicatie ook de unieke code die op de sticker vermeld staat op de AyPlay Mediaplayer.<br />
<br />
                  Sanamedia<br />
                  support@sanamedia.nl <br />
                  Telefoon: 088 - 053 71 81<br />
<br />
                  Met vriendelijk groet,<br />
                  <br />
                  Sanamedia B.V.
                </Typography>
              </ListItemText>
            </ListItem>

          </List>
        </Box>
      </Container>
    </Box>
  );
}
export default Support