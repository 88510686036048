/*



*/

import React, { useContext, useState } from 'react';
import { Avatar, IconButton, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material';
import nlFlag from './flags/nl.png';
import enFlag from './flags/en.png';
import { LanguageContext } from './App';

const LanguageButton = (props) => {

  const [ anchorEl, setAnchorEl ] = useState(null);
  
  const { setLanguage } = props; 
  const language = useContext(LanguageContext);

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLanguage = (lang) => {
    setLanguage(lang);
    setAnchorEl(null);
  }

  return(
    <>
      <IconButton 
        sx={{position: 'absolute', top: 0, right: 5, zIndex: 4}}
        size='small'
        onClick={e=>setAnchorEl(e.currentTarget)}
      >
        <Avatar variant='square' src={language === "en" ? enFlag : nlFlag} />
      </IconButton>

      <Menu
        id="language-selector"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguage('nl')}>
          <ListItemAvatar>
            <Avatar variant='square' src={nlFlag} />
          </ListItemAvatar>
          <ListItemText>
            Nederlands
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguage('en')}>
          <ListItemAvatar>
            <Avatar variant='square' src={enFlag} />
          </ListItemAvatar>
          <ListItemText>
            English
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
export default LanguageButton;
