/*



*/

/*



*/

import React, { useContext } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import BigDivider from './common/big-divider.svg';
import SmallDivider from './common/small-divider.svg';
import RadioTower from './common/radio-tower.png';
import SoundWave from './common/sound-wave.png';
import ShoppingBag from './common/shopping-bag.png';
import { LanguageContext } from './App';

export const TopBox= styled('section')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
}));

const Home = (props) => {

  const language = useContext(LanguageContext);

  return(
    <>
    <TopBox>
      <Container
        sx={{
          mt: 25,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justify: 'flex-start',
        }}
      >
        <Box component="img"
          src="/logo.png"
          alt="Sanamedia Logo"
          sx={{margin: 'auto', width: '100%', maxWidth: 492}}
        />
        <Button sx={{mt: 5, pl: 5, pr: 5}} variant="contained" component={Link} to="/login">Login</Button>
      </Container>
    </TopBox>

    <TopBox>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, rgba(2,0,36,0.01) 0%, rgba(255,255,255,1) 25%)',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={BigDivider}
          alt="divider"
          sx={{width: '100%', textAlign: 'center', mb: 8}}
        />
        <Container sx={{width: '100%', maxWidth: 1600}} maxWidth={false}>
          <Grid container alignItems="stretch" spacing={8} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  borderRadius: 8,
                  backgroundImage: 'linear-gradient(180deg, rgba(233,243,255,1) 0%, rgba(255,255,255,1) 100%)',
                  minHeight: 200,
                  p: 4
                }}
              >
                <Box component="img" src={RadioTower} alt="radiotower" sx={{width: 100}}/>
                <Typography variant="button" paragraph fontSize={20}>
                  { language === "en" 
                    ? "Broadcast"
                    : "Broadcast"
                  }
                </Typography>
                <Typography paragraph align="left">
                  {language === "en" 
                    ? "Sanamedia provides complete in-store radio broadcasts. By utilizing the latest technologies, we ensure a reliable broadcast in high quality."
                    : "Sanamedia verzorgt de volledige in-store radio uitzending. Door gebruik te maken van de nieuwste technieken heeft u een betrouwbare uitzending in hoge kwaliteit."
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  borderRadius: 8,
                  backgroundImage: 'linear-gradient(180deg, rgba(233,243,255,1) 0%, rgba(255,255,255,1) 100%)',
                  minHeight: 200,
                  p: 4
                }}
              >
                <Box component="img" src={SoundWave} alt="radiotower" sx={{width: 100}}/>
                <Typography variant="button" paragraph  fontSize={20}>
                  { language === "en"
                    ? "Custom Options"
                    : "Maatwerk"
                  }
                </Typography>
                <Typography paragraph align="left">
                  {language=== "en" 
                    ? "The music heard in the store significantly influences the perception of your brand and directly impacts customer behavior. Sanamedia creates broadcasts that can be fully customized to your preferences."
                    : "De muziek die te horen is in de winkel is bepalend voor het gevoel bij uw merk en heeft directe invloed op gedrag van klanten. Sanamedia maakt op maat gemaakte uitzendingen, deze uitzendingen zijn volledig aan uw wensen aan te passen."
                  }
                </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  borderRadius: 8,
                  backgroundImage: 'linear-gradient(180deg, rgba(233,243,255,1) 0%, rgba(255,255,255,1) 100%)',
                  minHeight: 200,
                  p: 4
                }}
              >
                <Box component="img" src={ShoppingBag} alt="radiotower" sx={{width: 100}}/>
                <Typography paragraph variant="button" fontSize={20}>
                  {language === "en"
                    ? "Customer Login"
                    : "Klanten Login"
                  }
                </Typography>
                <Typography paragraph align="left">
                  {language === "en" 
                    ? "Already a customer? Log in directly to listen to or adjust your broadcast."
                    : "Ben je al klant? Log direct in om uw uitzending te beluisteren of aan te passen."
                  }
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    pl: 8,
                    pr: 8,
                    border: 4,
                    borderRadius: 0,
                    color: '#000000',
                    bgcolor: '#ffffff',
                    '&:hover': {
                      border: 4,
                      borderRadius: 0,
                      borderColor: 'primary.dark',
                      color: '#000000',
                      bgcolor: '#ffffff',
                    },
                  }}
                  component={Link}
                  to="/login"
                >Login</Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          component="img"
          src={SmallDivider}
          alt="divider"
          sx={{width: '100%', textAlign: 'center', mt: 5, mb: 5}}
        />
        <Container sx={{pb: 8}}>
          <Button
            variant="outlined"
            sx={{
              p: 2,
              border: 4,
              borderRadius: 0,
              color: '#000000',
              textTransform: 'none',
              bgcolor: '#ffffff',
              '&:hover': {
                border: 4,
                borderRadius: 0,
                borderColor: 'primary.dark',
                color: '#000000',
                bgcolor: '#ffffff',
              },
            }}
            component={MuiLink}
            href="mailto:sales@sanamedia.nl"
          >
            {language === "en"
              ? "Questions? Contact Us"
              : "Heb je nog vragen?"
            }
          </Button>
          <Box sx={{m: 8}}></Box>
          <Typography paragraph fontSize={'1.5rem'}>
            {language === "en" 
              ? ""
              : "Meer weten over de mogelijkheden van SanaMedia? E-mail ons op info@sanamedia.nl"
            }
          </Typography>
        </Container>
      </Box>
    </TopBox>
    </>
  );
}
export default Home;
