/*



*/


import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Navigate } from 'react-router-dom';
import SmallDivider from './common/small-divider.svg';

export const Stream = (props) => {

  let queryString = "";
  if( navigator.userAgent.includes("Firefox") ) {
    queryString = "?refresh=" + Date.now();
  }

  const { stream  } = props;

  if( !stream ) {
    return <Navigate to="/login" replace={true} />
  }

  return(
    <>
    <Box
      component="section"
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
      }}
    >
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/logo.png"
          alt="Sanamedia Logo"
          sx={{
            mt: 5,
            maxHeight: 200,
          }}
        />
        <Box
          sx={{
            mt: 8,
            textAlign: 'center',
          }}
        >
          <Box sx={{textAlign: 'left', borderRadius: 4, p:6, mt:2, boxShadow: 2, bgcolor: 'common.white', maxWidth: 500}}>

          { stream &&
            <>
              <Typography variant="h5" sx={{mb: 3}}>{stream.name}</Typography>
              <audio controls style={{width: '100%', maxWidth: 150}}>
                { stream.urls.map(url => (
                  <source key={url} src={url + queryString} type={url.includes(".mp3") ? "audio/mpeg" : "audio/mp4"} />
                ))}
                Your browser does not support the audio tag or stream codec. <br />
                Direct link: <a href={stream.urls[0] || ""} target="_blank" rel="noreferrer">{stream.urls[0]}</a>
              </audio>
              <br />
              <br />
              <Link href={stream.playlist}>
                {stream.name} Radio Playlist (contains all streams)
              </Link>
              <br />
              <br />
              {stream.urls.map( (url, index) => (
                <Box sx={{mb: 2}} key={index}>
                  <Link href={url}>
                    Direct Stream {index+1}
                  </Link>
                </Box>
              ))}
            </>
          }
          </Box>
        </Box>
      </Container>
    </Box>
    <Box
      component="img"
      src={SmallDivider}
      alt="divider"
      sx={{
        width: '100%',
        textAlign: 'center',
        backgroundImage: 'linear-gradient(180deg, rgba(2,0,36,0.01) 0%, rgba(255,255,255,1) 25%)',
        p: 0,
      }}
    />
    </>
  );
}
export default Stream;
