/*



*/
import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = (props) => {
  return(
    <Box
      component="section"
      sx={{
        display: 'flex',
        bgcolor: 'secondary.light',
        position: 'relative',
        alignItems: 'center',
        height: {
          xs: 'auto',
          sm: '80vh',
        },
        minHeight: {
          xs: 'auto',
          sm: 500
        },
        maxHeight: {
          xs: 'auto',
          sm: 1300,
        },
      }}
    >
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Box>
          <Typography variant="h3">404 - Page not found</Typography>
        </Box>
      </Container>
    </Box>
  );
}
export default NotFound;
