/*



*/

import React, { createContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import theme from './Theme';
import Home from './Home';
import NotFound from './NotFound';
import Stream from './Stream';
import Login from './Login';
import Footer from './Footer';
import useStream from './hooks/useStream';
import backgroundImage from './background.jpg';
import Support from './Support';
import LanguageButton from './LanguageButton';


export const LanguageContext = createContext("nl");

function App() {

  const { login, stream, loginError } = useStream();
  const navigate = useNavigate();

  const [language, setLanguage ] = useState("nl");

  React.useEffect(() => {
    if( stream && stream.page ) {
      navigate(stream.page);
    }
  }, [stream, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <LanguageContext.Provider value={language}>
      <CssBaseline />
      <Box sx={{minHeight: '100vh', backgroundColor: '#52bad5', bgcolor: 'secondary.light', backgroundImage: `url('${backgroundImage}')`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <LanguageButton setLanguage={setLanguage} />
        <Routes>
          <Route exact path="/login" element={<Login onLogin={login} error={loginError}/>} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/:stream" element={<Stream stream={stream} />} />
          <Route exact path="/" element={<Home/>} />
          <Route path="/*" element={<NotFound/>} />
        </Routes>
        <Footer />
      </Box>
      </LanguageContext.Provider>
    </ThemeProvider>
  );
}

export default App;
