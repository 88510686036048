/*



*/

import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';


// function Copyright() {
//   return (
//     <React.Fragment>
//       {'© '}
//       <Link color="inherit" href="https://sanamedia.nl/">
//         SanaMedia
//       </Link>{' '}
//       {new Date().getFullYear()}
//     </React.Fragment>
//   );
// }

const Footer = (props) => {
  return(
    <AppBar component="footer" color="primary" position="relative">
      <Toolbar>
        <Box sx={{textAlign: 'center', width: '100%'}}>
          <Typography color="inherit" fontSize={'1.6rem'}>Sanamedia.nl - info@sanamedia.nl</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Footer;
