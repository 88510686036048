/*



*/


import React from 'react';
import Box from '@mui/material/Box';
import SmallDivider from './common/small-divider.svg';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const Login = (props) => {

  const { onLogin, error } = props;

  const [ username, setUsername ] = React.useState("");
  const [ password, setPassword ] = React.useState("");

  const handleSubmit = ( e ) => {
    e.preventDefault();
    onLogin(username, password)
  }

  return(
    <>
    <Box
      component="section"
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
      }}
    >
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/logo.png"
          alt="Sanamedia Logo"
          sx={{
            mt: 5,
            maxHeight: 200,
          }}
        />
        <Box
          sx={{
            mt: 8,
            textAlign: 'center',
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              textAlign: 'left',
              borderRadius: 4,
              p:6,
              mt:2,
              boxShadow: 2,
              bgcolor: 'common.white',
              maxWidth: 500
            }}
          >

            <Typography variant="h5" sx={{mb:3}}>Please Login</Typography>
            <TextField
              label="username"
              value={username}
              onChange={e=>setUsername(e.target.value)}
              fullWidth
              sx={{
                mb: 3
              }}
              error={error}
            />
            <TextField
              label="password"
              type="password"
              value={password}
              onChange={e=>setPassword(e.target.value)}
              fullWidth
              error={error}
              helperText={error ? "Login mislukt, klopt uw wachtwoord?" : undefined}
            />
            <Button variant="contained" type="submit" sx={{mt:3, mb: 1}}>Login</Button>

          </Box>
        </Box>
      </Container>
    </Box>
    <Box
      component="img"
      src={SmallDivider}
      alt="divider"
      sx={{
        width: '100%',
        textAlign: 'center',
        backgroundImage: 'linear-gradient(180deg, rgba(2,0,36,0.01) 0%, rgba(255,255,255,1) 25%)',
        p: 0,
      }}
    />
    </>
  );
}
export default Login
