/**/


import { useState, useCallback } from "react";

export const useStream = () => {

  const [ stream, setStream ] = useState();
  const [ loginError, setLoginError ] = useState(false);

  const login = useCallback( ( username, password ) => {
    fetch("https://sanamedia.nl/access/login",  {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify({
        user: username,
        password: password,
      })
    })
    .then( response => {
      return response.json()
    })
    .then( data => {
      setLoginError(false);
      if (data.error) {
        console.error(data.error);
        setLoginError(true);
      } else {
        setStream(data);
      }
    })
    .catch(err => {
      console.error("login error:", err)
      setLoginError(true);
    });
  }, []);

  return {
    stream,
    login,
    loginError,
  }
}
export default useStream;
